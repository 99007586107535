import React, { useEffect, useRef, useState } from 'react';

import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Button from '../components/button.jsx';
import Layout from '../components/layout.jsx';
import Section from '../components/section.jsx';
import TitleBlock from '../components/title-block.jsx';
import FirstMeeting from '../components/first-meeting.jsx';

import useTranslate from '../hooks/use-translate.js';
import useLoadingState from '../hooks/use-loading-state.js';
import Title from '../components/title.jsx';

export default function Contact(props) {
	let { data } = props;
	let { sanityContactPage, sanityCompanyInfo, hubspotForm } = data;
	let { title, officesBlockTitle, contactFormTitle, seo } = sanityContactPage;
	let { offices, information } = sanityCompanyInfo;
	let t = useTranslate('contact');

	let renderInformation = [];
	for (let info of information || []) {
		renderInformation.push(
			<a
				key={info._key}
				href={info.type === 'link' ? info.url : `${info.type}${info.value}`}
				className="font-light transition-transform transform md:hover:translate-x-2 hover:text-primary focus:translate-x-2 focus:text-primary focus:outline-none"
			>
				{info.value}
			</a>,
		);
	}

	let renderOffices = [];
	for (let office of offices || []) {
		renderOffices.push(
			<div key={office._key}>
				<img src={office.image?.asset?.url} alt={office.name} className="w-80 h-80" />

				<h4 className="text-2xl border-b-2 text-primary border-primary">{office.name}</h4>
				<a
					href={office.url}
					className="inline-block mt-4 text-sm font-light uppercase transition-transform transform hover:translate-x-2 hover:text-primary focus:translate-x-2 focus:text-primary focus:outline-none"
				>
					{office.address_1}
					<br />
					{office.address_2}
					<br />
					{office.address_3}
				</a>
			</div>,
		);
	}

	return (
		<Layout seo={seo}>
			<Section>
				<div className="grid gap-12 lg:grid-cols-2">
					<div className="grid gap-8 xl:max-w-3xl">
						<h1 className="text-4xl md:text-5xl 2xl:text-6xl self-y-center">
							<TitleBlock title={title} />
						</h1>
						<div className="flex flex-col items-start w-48 self-y-end">
							<h2 className="py-2 text-2xl border-b-2 text-primary border-primary">{t('contact-information')}</h2>
							<div className="flex flex-col items-start mt-4">{renderInformation}</div>
						</div>
					</div>

					<div className="relative w-full rounded-full lg:h-full lg:w-auto lg:max-w-none">
						<StaticImage src="../images/ship.svg" alt="Spaceship" />
					</div>
				</div>
			</Section>

			<Section>
				<Title>{contactFormTitle}</Title>
				<div className="grid w-full max-w-4xl mx-auto mt-4 md:mt-12">
					<HubspotForm form={hubspotForm} />
				</div>
			</Section>

			<Section>
				<Title>{officesBlockTitle}</Title>
				<div className="grid gap-12 mt-4 xl:gap-16 md:grid-cols-2 xl:grid-cols-4">{renderOffices}</div>
			</Section>

			<FirstMeeting />
		</Layout>
	);
}

function HubspotForm(props) {
	let { form } = props;
	let { formFieldGroups } = form;

	let formRef = useRef();
	let t = useTranslate('contact');
	let [executePostContactForm, postingContactForm, pendingPostContactForm] = useLoadingState(handlePostContactForm);
	let [error, setError] = useState();
	let [success, setSuccess] = useState();

	async function handlePostContactForm() {
		let formData = new FormData(formRef.current);
		let response = await fetch(
			'https://forms.hubspot.com/uploads/form/v2/5252152/91deb670-6338-4f0c-9244-d628494e1639',
			{ method: 'POST', body: formData },
		);

		if (response.ok) {
			setSuccess(true);
			formRef.current.reset();
		} else {
			setError(true);
		}
	}

	async function handleSubmitContact(event) {
		event.preventDefault();
		if (postingContactForm) return;

		executePostContactForm();
	}

	useEffect(() => {
		if (error) {
			setTimeout(() => {
				setError(false);
			}, 7500);
		}
	}, [error]);

	useEffect(() => {
		if (success) {
			setTimeout(() => {
				setSuccess(false);
			}, 7500);
		}
	}, [success]);

	let renderRows = [];
	let id = 0;
	for (let fieldGroup of formFieldGroups) {
		let renderCols = [];
		for (let field of fieldGroup.fields) {
			let { fieldType } = field;
			let render;
			if (fieldType === 'text') {
				render = (
					<input
						id={field.name}
						name={field.name}
						className="block w-full py-1 mt-1 text-white bg-transparent border-b-2 focus:outline-none border-tertiary focus:border-primary"
						type={field.name === 'email' ? 'email' : field.fieldType}
						required={field.required}
					/>
				);
			} else if (fieldType === 'textarea') {
				render = (
					<textarea
						id={field.name}
						name={field.name}
						className="block w-full mt-1 text-white bg-transparent border-b-2 resize-y border-tertiary focus:outline-none focus:border-primary"
						type={field.fieldType}
						required={field.required}
						rows="3"
					></textarea>
				);
			}

			renderCols.push(
				<div key={field.name} className="flex-grow focus-within:text-primary text-tertiary">
					<label htmlFor={field.name} className="text-sm">
						{field.label}
						{field.required ? '*' : ''}
					</label>
					{render}
				</div>,
			);
		}

		renderRows.push(
			<div key={id++} className="flex flex-col flex-grow space-y-4 md:space-y-0 md:flex-row md:space-x-8">
				{renderCols}
			</div>,
		);
	}

	let renderSuccess;
	if (success) {
		renderSuccess = <div className="text-sm text-primary">{t('success')}</div>;
	}

	let renderError;
	if (error) {
		renderError = <div className="text-sm text-red-500">{t('error')}</div>;
	}

	return (
		<form className="grid gap-4 md:gap-8" onSubmit={handleSubmitContact} ref={formRef}>
			{renderError}
			{renderSuccess}
			{renderRows}
			<span className="mx-auto">
				<Button type="submit" pending={pendingPostContactForm} disabled={pendingPostContactForm}>
					{t('send')}
				</Button>
			</span>
		</form>
	);
}

export const query = graphql`
	query ContactPage($language: String!) {
		sanityContactPage(lang: { eq: $language }) {
			title {
				children {
					_key
					text
					marks
				}
			}
			officesBlockTitle
			contactFormTitle
			seo {
				description
				image {
					asset {
						url
					}
				}
			}
		}

		sanityCompanyInfo {
			information {
				_key
				type
				value
				url
			}
			offices {
				_key
				name
				headquarter
				address_1
				address_3
				address_2
				url
				image {
					asset {
						url
					}
				}
			}
		}

		hubspotForm(id: { eq: "91deb670-6338-4f0c-9244-d628494e1639" }) {
			formFieldGroups {
				fields {
					name
					enabled
					fieldType
					label
					labelHidden
					placeholder
					isSmartField
					defaultValue
					description
					required
				}
			}
			id
			isPublished
		}
	}
`;
